nz-pagination {
  @apply w-full h-[56px] md:h-[32px];
}

.ant-btn-icon-only.ant-btn-sm,
.ant-pagination.mini .ant-pagination-item {
  @apply rounded-full;
}
.ant-btn-icon-only.ant-btn-sm,
.ant-pagination-total-text .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply border-wz-light-blue text-wz-gray;
}
.ant-pagination.mini .ant-pagination-item {
  @apply border-wz-primary bg-wz-primary;
}
.ant-pagination-item a {
  @apply text-wz-gray;
}
.ant-pagination-item-active a {
  @apply bg-wz-primary text-white rounded-full border-wz-primary;
}
.ant-pagination-item-active a:hover {
  @apply text-white;
}


.ant-pagination ul, .ant-pagination ol {
  @apply flex justify-end flex-wrap;
  row-gap: 10px;
}

.ant-pagination.mini .ant-pagination-total-text {
  @apply mr-0 ml-0 mb-0 mt-0;
}

.ant-pagination.mini .ant-pagination-prev {
  @apply mr-1;
}
.ant-pagination.mini .ant-pagination-next {
  @apply ml-1;
}

@screen md {
  .ant-pagination ul, .ant-pagination ol {
    @apply flex-nowrap;
  }
}

div[footer] {
  @apply relative md:z-10 bottom-0 md:sticky bg-white pt-4 md:pt-6 -mt-4 md:-mt-6 pb-4 md:pb-6 -mb-4 md:-mb-6;
}

@import '../helper/helper';

.skeleton-container {
  @apply max-w-[1500px] mx-auto flex gap-6 p-6;
}

.skeleton-container .sidebar {
  @apply w-[250px];
  span {
    @apply h-[500px];
  }
}

.skeleton-container .content {
  @apply flex flex-col gap-6 flex-1;
}

nz-skeleton-element {
  @apply w-full #{!important};

  span {
    @apply w-full rounded-lg #{!important};
  }
}

nz-skeleton-element.header {
  span {
    @apply h-20 #{!important};
  }
}

nz-skeleton-element.filter-section {
  span {
    @apply h-14 #{!important};
  }
}

nz-skeleton-element.full-card {
  span {
    @apply h-72 #{!important};
  }
}

.table-section {
  @include generate-grid(100%, 1fr, 12px);

  span {
    @apply h-10 #{!important};
  }


}

.table-section.card {
  @include generate-grid(300px, 1fr, 12px);
  span {
    @apply h-96 #{!important};
  }
}


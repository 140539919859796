


.form-group{
  @apply flex flex-col w-full;
}

.form-group.show-errors {
  .form-input-group {
    .hint {
      @apply hidden;
    }
    .error {
      @apply block;
    }
  }
}



.form-input-group {
  @apply mb-4 w-full;

  label {
    @apply block mb-2 text-sm text-wz-gray;
  }

  input, select, textarea, nz-input-group {
    @apply py-2 text-sm;
  }

  .hint {
    @apply text-sm text-gray-600 block mt-1;
  }

  .error {
    @apply text-xs hidden text-red-500 mt-1;
  }

}

.form-input-group.show-errors {

  .hint {
    @apply hidden;
  }

  .error {
    @apply block;
  }
}

//.form-group .form-input-group:last-child {
//  @apply mb-0;
//}

.form-input-group.required {

  label::after {
    @apply text-wz-primary;
    content: '*';
  }

}

.ant-picker {
  @apply h-10;
}

// selector de tags
nz-select.tags-black:not(.spacing) .ant-select-selector {
  padding: 0 !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-large .ant-picker-input > input,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 14px;
}
nz-select.tags-black .ant-select-selector .ant-select-selection-item {
  @apply flex items-center bg-black text-white py-0.5 px-1.5 mx-0.5 #{!important};
  min-height: 22px !important;
}

nz-select.tags-black .ant-select-selector .ant-select-selection-item .ant-select-selection-item-remove {
  @apply text-white #{!important};
}

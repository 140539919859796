nz-breadcrumb.main-breadcrumb {
  .ant-breadcrumb-link a {
    @apply no-underline text-wz-gray;
  }

  .ant-breadcrumb-separator {
    @apply mx-1;
  }

  nz-breadcrumb-item:last-child {
    .ant-breadcrumb-link a {
      @apply text-wz-primary font-semibold;
    }
  }
}

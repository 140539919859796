.popover-title {

}

.cdk-overlay-container div.ant-popover-inner {
  @apply overflow-hidden rounded-lg;
}
.cdk-overlay-container div.ant-popover-inner .ant-popover-title {
  @apply p-4;
}

.cdk-overlay-container div.ant-popover-inner .ant-popover-inner-content {
  @apply p-0;
}

.nz-tree-view {
  transition: width 0.27s ease-in-out;

  .ant-tree-list-holder-inner {
    @apply gap-2.5;
  }

  .ant-tree-show-line .ant-tree-indent-unit::before {
    margin-right: -1px;
  }

  nz-tree-node {
    @apply pb-0 #{!important};
  }

  nz-tree-node-option {
    @apply cursor-pointer;
  }

  .ant-tree-switcher-leaf-line::before {
    @apply mr-0 -bottom-2.5;
  }

  nz-tree-node-indents span:first-child::before {
    content: none !important;
  }

  .ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
    height: 6px!important;
  }


  .ant-tree-switcher-leaf-line::after {
    border-bottom-left-radius: 8px;
    border-left: 1px solid #d9d9d9;
    left: 11px;
  }

  .ant-tree-treenode-disabled span span {
    @apply text-gray-400 #{!important};
  }

  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: transparent;
  }
}

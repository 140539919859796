//.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
//  @apply border border-wz-border w-2.5 h-2.5 #{!important};
//}
//
.ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  @apply relative top-[1px] h-[1px];
}
////
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  @apply bg-wz-primary border-wz-primary relative top-[1px];
}

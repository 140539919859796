.ant-input-number-input {
  font-size: 14px;
}

nz-input-group.cdk-focused > span > span,
nz-input-group:hover > span > span{
  @apply text-wz-primary;
}

nz-input-group nz-input-number {
  @apply border-0 outline-0 shadow-none #{!important};
}


.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply border-wz-primary ;
  box-shadow: none;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused, .ant-input:focus, .ant-input-focused {
  box-shadow: none;
}

nz-select:hover  span, .ant-select-focused span {
  @apply text-wz-primary #{!important};
}


nz-option-container.ant-select-dropdown-placement-bottomLeft {
  @apply rounded-tl-none rounded-tr-none mt-[-1px];

}

nz-option-container.ant-select-dropdown-placement-topLeft {
  @apply rounded-bl-none rounded-br-none mb-[-1px];

}


nz-option-container.ant-select-dropdown {
  @apply shadow-none border-wz-primary border p-0;
}

.select-top.ant-select-focused  .ant-select-selector {
  @apply rounded-t-none  ;

}
.select-bottom.ant-select-focused .ant-select-selector {
  @apply rounded-b-none ;

}

nz-input-group.bg-prefix > span.ant-input-prefix {
  @apply bg-wz-light-gray-aux px-2 rounded-l;
  margin: 2px 0px 2px 2px;
}

// STYLES "DISABLED" WHEN THE VIEW IS OPPORTUNITY FORM
.semi-disabled-form .ant-input[disabled],
.semi-disabled-form .ant-input-affix-wrapper-disabled,
.semi-disabled-form .ant-input-number-disabled,
.semi-disabled-form .ant-radio-disabled + span,
.semi-disabled-form .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker.ant-picker-disabled {
  @apply bg-transparent resize-none text-wz-black #{!important};
}
.semi-disabled-form .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
  @apply border-wz-primary #{!important};
}
.semi-disabled-form .ant-radio-disabled .ant-radio-inner::after {
  @apply bg-wz-primary #{!important};
}
.semi-disabled-form .ant-select-disabled nz-select-arrow {
  @apply hidden;
}
.ant-picker-input > input[disabled],
.ant-checkbox-disabled + span {
  @apply text-wz-black;
}

// VIEW ALL TEXT IN SELECTS
.ant-select-item-option-content {
  @apply whitespace-normal #{!important};
}

.nz-filter-button-group {
  button {
    @apply flex items-center justify-center bg-white border-wz-border text-wz-gray  #{!important};

    span.box {
      @apply border-wz-gray;
    }

    &:hover, &:active, &.active {
      @apply bg-white border-wz-primary text-wz-primary #{!important};

      span.box {
        @apply border-wz-primary;
      }
    }

    &:focus {
      @apply bg-white border-wz-border text-wz-gray;
    }

    &:first-child:not(:last-child) {
      @apply border-r-wz-primary #{!important};
    }

    &:last-child:not(:first-child), .ant-btn-primary + .ant-btn-primary {
      @apply border-l-wz-primary #{!important};
    }
  }
}

/*button.ant-btn {
  &.btn-green {
    @apply flex bg-wz-green/10 border-transparent hover:bg-wz-green/5 hover:border-transparent items-center shadow-none;
    span, i {
      @apply text-wz-green #{!important};
    }
  }
}*/

button.ant-btn[bordered] {
  &.btn-green {
    @apply flex bg-white border-wz-green hover:border-wz-green/80 text-wz-green hover:text-wz-green/80 items-center shadow-none;
    span, i {
     //  @apply text-wz-green ;
    }
  }
}

button.ant-btn-primary[bordered] {
  @apply bg-white border-wz-primary text-wz-primary #{!important};
  text-shadow: none;
}

button.table-action-button,
button.table-action-button:hover {
  @apply bg-wz-light-blue border-wz-light-blue text-wz-primary shadow-none h-[28px] w-[28px] min-h-[28px] min-w-[28px] rounded-full;
}

button.table-action-button.ant-btn[nzDanger] {
  @apply bg-wz-light-blue border-wz-light-blue shadow-none #{!important};
  span, i {
    @apply text-wz-primary #{!important};
  }
}

button.table-action-button.ant-btn[nzDanger]:hover {
  @apply bg-wz-red/10 border-transparent shadow-none #{!important};
  span, i {
    @apply text-wz2 #{!important};
  }
}

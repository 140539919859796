h1 {
  @apply text-4xl font-bold;
}
h2 {
  @apply text-3xl font-bold;
}
h3 {
  @apply text-2xl font-bold;
}
h4 {
  @apply text-xl font-bold;
}
h5 {
  @apply text-lg font-bold;
}
h6 {
  @apply text-xs uppercase text-gray-500;
}
p {
  @apply text-base;
}

a {
  @apply text-slate-600 underline hover:underline hover:text-slate-900 ;
}

.text-title {
  font-family: 'Baloo2-Bold' !important;
}

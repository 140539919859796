.ant-timeline-item-head-blue {
  @apply bg-white h-[16px] w-[16px] top-[-4px] left-[0px];
}


nz-timeline.active > ul > li > div.ant-timeline-item-head.ant-timeline-item-head-blue {
  @apply border-wz-primary;
}

.ant-timeline-item-tail {
  @apply border-wz-primary border-l top-[15px] left-[7px];
  height: calc(100% - 22px);
}

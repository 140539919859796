.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  @apply shadow-none bg-transparent p-0 md:p-5 w-full md:w-[500px] #{!important};
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper.drawer-complete {
  @apply shadow-none bg-transparent p-0 md:p-5 w-full md:w-[85%] #{!important};
}

.ant-drawer .ant-drawer-content {
  @apply md:rounded-lg;
}

.ant-drawer-header {
  @apply border-b-0 pb-0;
}


.footer-w-full .ant-drawer-footer {
  @apply p-0;
}

.ant-drawer-footer {
  @apply p-0;
}

/* Default modal */
.ant-modal {
  .ant-modal-content {
    @apply rounded-lg overflow-hidden;

    .ant-modal-header {
      @apply border-b-0 px-8 pt-8 pb-0;

      .ant-modal-title { }
    }

    .ant-modal-body {
      @apply py-4 px-8;
    }

    .ant-modal-footer {
      @apply bg-gray-50 border-t-0 px-8 py-4;
    }

  }
}

/* Modal confirm */
.ant-modal {
  &.ant-modal-confirm {

    .ant-modal-content {
      @apply rounded-lg overflow-hidden;

      .ant-modal-body {
        @apply p-0;

        .ant-modal-confirm-body-wrapper {
          @apply flex flex-col;

          .ant-modal-confirm-body {
            @apply px-8 pt-8;

            .anticon {
              @apply hidden;
            }

            .ant-modal-confirm-content {
              @apply ml-0 py-4 mt-0 #{!important};
            }
          }

          .ant-modal-confirm-btns {
            @apply bg-gray-50 px-8 py-4 flex justify-end gap-4 m-0;

            [nz-button] {
              height: 40px;
              padding: 6.4px 15px;
              font-size: 16px;
              border-radius: 4px;


              &.ant-btn-dangerous {
                @apply ml-0;
              }
            }
          }

        }
      }

      .ant-btn-primary.ant-btn-dangerous {
        @apply bg-wz-red border-wz-red;
      }
    }
  }
}

.ant-modal.ant-modal-confirm .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns [nz-button]:nth-child(1) {
  color: #111827;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

/* Notification modal alert */
.ant-message-custom-content {
  @apply flex items-center;
}
.ant-message-custom-content > span {
  @apply top-0 #{!important};
}

/* modal drop down */
.ant-dropdown-menu {
  @apply p-2 rounded-lg;
}

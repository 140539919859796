* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}
@import './fonts';
@import './partial/form';
@import './partial/text';
@import './partial/tree-view';
@import './partial/table';
@import './partial/pagination';
@import './partial/breadcrumb';
@import './partial/button';
@import './partial/skeleton';
@import './partial/calendar';
@import './partial/drawer';
@import './partial/steps';
@import './partial/input';
@import './partial/timeline';
@import './partial/modal';
@import './partial/time-picker';
@import './partial/alert';
@import './helper/helper';
@import "./partial/popover";
@import "./partial/document-viewer";
@import "./partial/tabset";
@import './partial/text-copy';

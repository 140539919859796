/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'assets/scss/global.scss';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

body {
  @apply overflow-hidden;
}

.anticon {
  vertical-align: 1px!important;
}


.ant-progress-status-success .ant-progress-bg {
  @apply bg-wz-green;
}

.mini-calendar.ant-picker {
  @apply h-auto #{!important};
}

.mini-calendar .ant-picker-panel-container {
  @apply shadow-none border-none;
}

.mini-calendar .ant-picker-panel .ant-picker-footer {
  @apply hidden;
}

.mini-calendar .ant-picker-header {
  @apply border-none h-[40px] flex items-center;
}

.mini-calendar .ant-picker-panel-container .ant-picker-panel {
  @apply border-none;
}

.mini-calendar .ant-picker-content th {
  @apply text-wz-gray;
}

.mini-calendar .ant-picker-cell-in-view {
  @apply text-wz-black text-sm #{!important};
}

.mini-calendar .ant-picker-header > button:nth-child(1),
.mini-calendar .ant-picker-header > button:last-child {
  @apply hidden;
}

.mini-calendar .ant-picker-date-panel {
  @apply w-full sm:w-[250px] md:w-[300px] xl:w-[350px];
}

.mini-calendar .ant-picker-date-panel .ant-picker-body {
  @apply p-0;
}

.mini-calendar .ant-picker-date-panel .ant-picker-content {
  @apply w-full;
}

.mini-calendar .ant-picker-header > button:nth-child(2) {
  @apply absolute right-[40px] flex items-center justify-center;
}

.mini-calendar .ant-picker-prev-icon::before,
.mini-calendar .ant-picker-next-icon::before {
  @apply h-[10px] w-[10px];
}

.mini-calendar .ant-picker-header > button:hover {
  @apply text-wz-primary;
}

.mini-calendar .ant-picker-header-view {
  @apply flex flex-row-reverse absolute left-0;
  width: inherit;
}

.mini-calendar .ant-picker-header > button:nth-child(4) {
  @apply absolute right-0 flex items-center justify-center;
}

.mini-calendar .ant-picker-header button:first-child {
  @apply ml-2;
}

.mini-calendar .ant-picker-header button {
  @apply text-wz-black font-bold text-base;
}

.mini-calendar .ant-picker-cell {
  @apply text-wz-border;
}

.mini-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  @apply rounded-full border-wz-primary;
}

.mini-calendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  @apply rounded-full bg-wz-primary;
}

.mini-calendar .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  @apply rounded-full bg-wz-primary/10;
}


// ----

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-panel-container {
  @apply shadow-none border-wz-border border;
  border-radius: 5px;
}

.ant-picker-header {
  @apply border-b-wz-border;
}

.ant-picker-panel .ant-picker-footer {
  @apply border-t-wz-border;
}

.ant-picker-content th {
  @apply text-wz-gray;
}

.ant-picker-cell-in-view {
  @apply text-wz-black text-sm #{!important};
}

.ant-picker-header button {
  @apply text-wz-black font-bold text-base;
}

.ant-picker-cell {
  @apply text-wz-border;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  @apply bg-wz-primary/10;
}

.ant-picker-panel-container .ant-picker-panel {
  border-width: 0;
}

/* .ant-picker:hover, .ant-picker-focused  */
.ant-picker:hover span, .ant-picker-focused span {
  @apply text-wz-primary #{!important};

}

.ant-table-thead > tr > th:first-child {
  // @apply rounded-bl;
}
.ant-table-thead > tr > th:last-child {
  // @apply rounded-br;
}
.ant-table-thead > tr > th.content-none::before {
  content: none #{!important};
}
.ant-table-thead > tr > th::before {
  @apply bg-wz-light-blue #{!important};
}
.ant-table-thead > tr > th {
  @apply py-2.5 font-semibold text-sm text-wz-black text-left bg-white border border-l-0 border-r-0 border-t-0 border-b-wz-light-blue;
  //border-top: 1px solid red;
  //border-bottom: 1px solid red;
  border-right: none !important;
}

.ant-table-tbody > tr > td {
  @apply text-left text-wz-gray text-sm py-3 border-wz-light-blue;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  @apply border-gray-200;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  @apply border-none;
}

.ant-table.ant-table-bordered > .ant-table-container {
  @apply border-none;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
  border-right: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  @apply bg-white;
}

.actions {
  @apply flex gap-2 items-center;
}

/* Connection request table */
.connection-request-table .ant-table-tbody > tr > td {
   @apply py-1 px-2;
}

.connection-request-table .nz-table-out-bordered > .ant-table-container {
  @apply border-wz-light-blue;
}


nz-table table tr:hover {
  td:not(.table-td-no-bg) {
    @apply bg-slate-50 cursor-pointer #{!important};
  }
}
/* / Connection request table */

@font-face {
  font-family: 'Baloo2-Bold';
  src: url('./../fonts/Baloo2-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloo2-ExtraBold';
  src: url('./../fonts/Baloo2-ExtraBold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloo2-Medium';
  src: url('./../fonts/Baloo2-Medium.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloo2-Regular';
  src: url('./../fonts/Baloo2-Regular.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Baloo2-SemiBold';
  src: url('./../fonts/Baloo2-SemiBold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


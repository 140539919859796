.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  @apply m-0;
}

.ant-tabs-tab {
  @apply p-0;
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
  @apply border-none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover {
  @apply text-wz-primary;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  @apply py-0 px-4;
}

.tab-menu-main-layout .ant-tabs-tab.ant-tabs-tab-active {
  @apply border md:border-none border-wz4 rounded bg-wz4/5 border-solid py-1 px-3 md:bg-inherit md:p-0;
}

.ant-tabs.ant-tabs-nav-operations-hidden .ant-tabs-nav-operations {
  @apply absolute invisible pointer-events-none;
}

.ant-tabs-ink-bar {
  @apply bg-wz-primary;
  // @apply hidden bg-wz4;
}

.document-viewer.prev-item {
  top: 50%;
  left: -10px;
  box-shadow: 0 0 15px rgba(0 0 0 / 20%);
  transform: translateY(-50%);
}
.document-viewer.next-item {
  top: 50%;
  right: -10px;
  box-shadow: 0 0 15px rgba(0 0 0 / 20%);
  transform: translateY(-50%);
}
